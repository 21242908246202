import { takeEvery } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import {
  setupPasswordSaga,
  loginSaga,
  logoutSaga,
  handleApiErrorSaga,
  forgetPasswordSaga,
  switchEmployerAdminAuthSaga,
} from './authSaga';

import {
  API_ERROR,
  FORGET_PASSWORD,
  LOGIN_FETCH,
  LOGOUT_FETCH,
  SETUP_PASSWORD,
  SWITCH_EMPLOYER_ADMIN_AUTH,
} from '../constants';

export function* rootAuthSaga() {
  yield takeEvery(LOGIN_FETCH, loginSaga);
  yield takeEvery(SETUP_PASSWORD, setupPasswordSaga);
  yield takeEvery(FORGET_PASSWORD, forgetPasswordSaga);
  yield takeEvery(SWITCH_EMPLOYER_ADMIN_AUTH, switchEmployerAdminAuthSaga);
  yield takeEvery(LOGOUT_FETCH, logoutSaga);
  yield takeEvery(API_ERROR, handleApiErrorSaga);
}
