/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
enum CalenderType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  HOURLY = 'hourly',
}

enum AppInfoType {
  WITHDRAWAL_LIMIT = 'WITHDRAWAL_LIMIT',
  FEES_LIST = 'FEES_LIST',
  BANK_INFO_LIST = 'BANK_INFO_LIST',
  COUNTRY_UTIL = 'COUNTRY_UTIL',
}

enum HistoryActType {
  PUSH = 'PUSH',
  POP = 'POP',
  REPLACE = 'REPLACE',
}

enum ActTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  PROCESSED = 'processed',
}

export { CalenderType, AppInfoType, HistoryActType, ActTypeEnum };
