enum ProviderEnum {
  XENDIT = 'XNDT',
  PERMATA = 'PMTI',
  HLB = 'HLBB',
  OCBC = 'OCBC',
  UOB = 'UOB',
  EWB = 'EWB',
  BUKOPIN = 'BKPN',
  FINANCEMY = 'FINANCEMY',
  FINANCEPH = 'FINANCEPH',
  FINANCEID = 'FINANCEID',
}

const providerLabel: { [key: string]: string } = {
  [ProviderEnum.XENDIT]: 'label.xendit',
  [ProviderEnum.HLB]: 'label.hlb',
  [ProviderEnum.OCBC]: 'label.ocbc',
  [ProviderEnum.UOB]: 'label.uob',
  [ProviderEnum.PERMATA]: 'label.permata',
  [ProviderEnum.EWB]: 'label.ewb',
  [ProviderEnum.BUKOPIN]: 'label.bukopin',
  [ProviderEnum.FINANCEMY]: 'label.finance(my)',
  [ProviderEnum.FINANCEPH]: 'label.finance(ph)',
  [ProviderEnum.FINANCEID]: 'label.finance(id)',
};

const providerSelect: { label: string; value: string }[] = [
  {
    label: providerLabel[ProviderEnum.XENDIT],
    value: ProviderEnum.XENDIT,
  },
  {
    label: providerLabel[ProviderEnum.HLB],
    value: ProviderEnum.HLB,
  },
  {
    label: providerLabel[ProviderEnum.OCBC],
    value: ProviderEnum.OCBC,
  },
  {
    label: providerLabel[ProviderEnum.EWB],
    value: ProviderEnum.EWB,
  },
  {
    label: providerLabel[ProviderEnum.BUKOPIN],
    value: ProviderEnum.BUKOPIN,
  },
  {
    label: providerLabel[ProviderEnum.UOB],
    value: ProviderEnum.UOB,
  },
  {
    label: providerLabel[ProviderEnum.PERMATA],
    value: ProviderEnum.PERMATA,
  },
  {
    label: providerLabel[ProviderEnum.FINANCEMY],
    value: ProviderEnum.FINANCEMY,
  },
  {
    label: providerLabel[ProviderEnum.FINANCEPH],
    value: ProviderEnum.FINANCEPH,
  },
  {
    label: providerLabel[ProviderEnum.FINANCEID],
    value: ProviderEnum.FINANCEID,
  },
];

export { ProviderEnum, providerLabel, providerSelect };
