/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { FormItem, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

export interface SelectInputProp {
  placeholder?: string;
  className?: string;
  label?: string | ReactNode;
  extra?: string | ReactNode;
  id: string;
  name: string;
  mode?: 'multiple' | 'tags';
  value?: string | number | any[] | null;
  defaultValue?: string | number;
  tooltip?: ReactNode;
  suffixIcon?: ReactNode;
  style?: CSSProperties;
  wrapperCol?: object; // ant design Col object
  labelCol?: object; // ant design Col object
  labelAlign?: 'right' | 'left';
  required?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
  labelInValue?: boolean;
  size?: 'small' | 'large';
  options: { label: any; value: any; data?: any }[] | undefined;
  onChange?: (value: any, option: any) => void;
  onSearch?: (value: string) => void;
  filterOption?: boolean | ((input: string, option: any /* ant design select option props */) => boolean);
  isUpdatedField?: boolean;
  sortingAscd?: boolean;
}

export const SelectInput: React.FC<SelectInputProp> = ({
  id,
  className,
  label,
  name,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  mode,
  onChange,
  onSearch,
  allowClear,
  showSearch,
  defaultValue,
  value,
  size,
  placeholder,
  filterOption,
  options,
  disabled,
  suffixIcon,
  style,
  showArrow,
  labelInValue,
  isUpdatedField,
  sortingAscd,
  extra,
}) => {
  const { t } = useTranslation();
  const [, meta] = useField({ name });

  const onIsUpdatedField = useMemo(() => {
    if (isUpdatedField) {
      return 'is-updated-field';
    }
    return '';
  }, [isUpdatedField]);

  return (
    <FormItem
      className={className}
      label={label}
      name={name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
      labelAlign={labelAlign}
      required={required}
      extra={extra}
    >
      <Select
        id={id}
        mode={mode}
        className={onIsUpdatedField}
        onChange={onChange}
        onSearch={onSearch}
        allowClear={allowClear}
        showSearch={showSearch}
        defaultValue={defaultValue}
        value={value}
        name={name}
        size={size}
        placeholder={placeholder}
        filterOption={filterOption}
        options={options}
        disabled={disabled}
        suffixIcon={suffixIcon}
        style={style}
        showArrow={showArrow}
        labelInValue={labelInValue}
        filterSort={(optionA, optionB) => {
          if (sortingAscd) {
            return ((optionA?.label as string) ?? '')
              .toLowerCase()
              .localeCompare(((optionB?.label as string) ?? '').toLowerCase());
          }
          return 0;
        }}
      />
    </FormItem>
  );
};

SelectInput.defaultProps = {
  allowClear: true,
  labelAlign: 'left',
};
