import { CountryCode } from 'common';
import {
  LOGIN_FETCH,
  SETUP_PASSWORD,
  LOGOUT_FETCH,
  API_ERROR,
  SET_FIRST_LOGIN_FLAG,
  FORGET_PASSWORD,
  SWITCH_EMPLOYER_ADMIN_AUTH,
} from './constants';

export interface ILogin {
  type: typeof LOGIN_FETCH;
  payload: {
    email: string;
    redirectUrl?: string;
    pw: string;
  };
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export interface ISetupPassword {
  type: typeof SETUP_PASSWORD;
  payload: {
    oldPassword: string;
    password: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IForgetPassword {
  type: typeof FORGET_PASSWORD;
  payload: {
    email: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ILogout {
  type: typeof LOGOUT_FETCH;
}

export interface IApiError {
  type: typeof API_ERROR;
  payload: {
    errorCode: number;
    errorMessage?: string;
  };
}

export interface ISetFirstLoginFlag {
  type: typeof SET_FIRST_LOGIN_FLAG;
  payload: boolean;
}

export interface ISwitchEmployerAdminAuth {
  type: typeof SWITCH_EMPLOYER_ADMIN_AUTH;
  payload: {
    eid: string;
    countryCode: CountryCode;
    redirectUrl?: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export type AuthAction = ILogin | IForgetPassword | ISetFirstLoginFlag | ISwitchEmployerAdminAuth;

export const login = (
  payload: ILogin['payload'],
  onSuccess: (data: any) => void,
  onError: (error: any) => void
): ILogin => ({
  type: LOGIN_FETCH,
  payload,
  onSuccess,
  onError,
});

export const setupPassword = (
  payload: ISetupPassword['payload'],
  onSuccess?: () => void,
  onError?: () => void
): ISetupPassword => ({
  type: SETUP_PASSWORD,
  payload,
  onSuccess,
  onError,
});

export const forgetPassword = (
  payload: IForgetPassword['payload'],
  onSuccess?: () => void,
  onError?: () => void
): IForgetPassword => ({
  type: FORGET_PASSWORD,
  payload,
  onSuccess,
  onError,
});

export const setFirstLogin = (payload: ISetFirstLoginFlag['payload']): ISetFirstLoginFlag => ({
  type: SET_FIRST_LOGIN_FLAG,
  payload,
});

export const switchEmployerAdminAuth = (
  payload: ISwitchEmployerAdminAuth['payload'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void,
  onError?: () => void
): ISwitchEmployerAdminAuth => ({
  type: SWITCH_EMPLOYER_ADMIN_AUTH,
  payload,
  onSuccess,
  onError,
});

export const logout = (): ILogout => ({
  type: LOGOUT_FETCH,
});

export const apiError = (payload: IApiError['payload']): IApiError => ({
  type: API_ERROR,
  payload,
});
