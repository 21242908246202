/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import { Button, Col, ColProps, Row, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile, UploadListType } from 'antd/lib/upload/interface';
import { FormItem } from 'formik-antd';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import React, { ReactNode, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isUndefinedOrNullOrEmpty } from 'common';
import { toastPush } from 'modules';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useField } from 'formik';

export interface UploadFileInputProp {
  preview?: string;
  label?: string;
  id: string;
  name: string;
  value?: string | number | any[];
  tooltip?: ReactNode;
  extra?: ReactNode;
  wrapperCol?: ColProps; // ant design Col object
  labelCol?: object; // ant design Col object
  labelAlign?: 'right' | 'left';
  required?: boolean;
  listType?: UploadListType;
  accept?: string;
  uploadClassName?: string;
  beforeUpload?: (file: RcFile, fileList: RcFile[]) => void;
  onPreview?: (file: UploadFile) => void;
  onChange?: (info: UploadChangeParam) => void;
  customRequest?: (options: RcCustomRequestOptions) => void;
  fileList: any[];
  maxCount?: number;
  disabled?: boolean;
  loading?: boolean;
  objectId?: string;
  onlyAcceptFileExtension?: string[];
  showFilename?: boolean;
}

export const UploadFileInput: React.FC<UploadFileInputProp> = ({
  id,
  onPreview,
  preview,
  beforeUpload,
  onlyAcceptFileExtension,
  listType,
  objectId,
  fileList,
  maxCount,
  value,
  showFilename,
  label,
  name,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  extra,
  uploadClassName,
  onChange,
  customRequest,
  accept,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, meta] = useField({ name });

  const handlePreview = useCallback(
    (file: UploadFile) => {
      if (onPreview) return onPreview(file);

      if (preview === undefined) return false;

      return fetch(preview)
        .then((res) => res.blob())
        .then((blob) => window.open(URL.createObjectURL(blob)));
    },
    [onPreview, preview]
  );

  const handleBeforeUpload = useCallback(
    (file: RcFile, fileLists: RcFile[]) => {
      const fileExtension = file.name.split('.').pop() ?? '';
      if (onlyAcceptFileExtension?.indexOf(fileExtension) === -1) {
        dispatch(
          toastPush({
            code: 400,
            type: 'error',
            title: 'ERROR',
            body: (
              <Trans
                i18nKey="label.onlyAllowExtensionFile"
                values={{ onlyAcceptFileExtension: onlyAcceptFileExtension?.join(', ')?.toUpperCase() }}
              />
            ),
          })
        );
        return Upload.LIST_IGNORE;
      }

      if (file.size > 5000000) {
        dispatch(
          toastPush({
            code: 400,
            type: 'error',
            title: 'ERROR',
            body: 'toastPush.maxFileSize5mb',
          })
        );
        return Upload.LIST_IGNORE;
      }

      if (beforeUpload) {
        return beforeUpload(file, fileLists);
      }

      return true;
    },
    [beforeUpload, dispatch, onlyAcceptFileExtension]
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('label.upload')}</div>
    </div>
  );

  const listTypeContent = () => {
    if (listType === 'picture-card')
      return isUndefinedOrNullOrEmpty(objectId) || (fileList !== undefined && fileList.length >= (maxCount || 1))
        ? null
        : uploadButton;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <Button disabled={disabled} loading={loading} className="d-flex align-items-center" icon={<UploadOutlined />}>
          {t('label.chooseFile')}
        </Button>
        {isUndefinedOrNullOrEmpty(value) && (
          <span className="ml-2" style={{ display: showFilename ? 'block' : 'none' }}>
            {t('label.noFileChosen')}
          </span>
        )}
      </div>
    );
  };

  return (
    <FormItem
      label={label}
      name={name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
      labelAlign={labelAlign}
      required={required}
      extra={extra}
    >
      <Row>
        <Col>
          <Upload
            id={id}
            name={name}
            maxCount={maxCount}
            className={uploadClassName}
            beforeUpload={handleBeforeUpload}
            onChange={onChange}
            customRequest={customRequest}
            fileList={fileList}
            accept={accept}
            onPreview={handlePreview}
            listType={listType}
            disabled={disabled}
          >
            {listTypeContent()}
          </Upload>
        </Col>
      </Row>
    </FormItem>
  );
};

UploadFileInput.defaultProps = {
  labelAlign: 'left',
  maxCount: 1,
  showFilename: true,
  customRequest: () => undefined,
};
