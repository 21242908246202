import React, { useEffect, useMemo } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router, Route, Switch } from 'react-router-dom';
import './App.less';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import idLocale from 'antd/es/locale/id_ID';
import enLocale from 'antd/es/locale/en_US';
import { RootState, selectCurrentCountry, selectCurrentLanguage } from 'modules';
import { LanguageCode } from 'common';
import moment from 'moment-timezone';
import { Toaster } from './components';
import { history } from './store';
import { ProtectedRoute, PublicRoute } from './routes/index';

import 'moment/locale/id';
import 'moment/locale/en-gb';

const Login = React.lazy(() => import('./views/Login'));
const SetupPassword = React.lazy(() => import('./views/SetupPassword'));
const ForgetPassword = React.lazy(() => import('./views/ForgetPassword'));
const SwitchEmployerCompany = React.lazy(() => import('./views/SwitchEmployerCompany'));
const Page404 = React.lazy(() => import('./views/Errors/Page404'));
const Page500 = React.lazy(() => import('./views/Errors/Page500'));
const TwoFA = React.lazy(() => import('./views/2FA'));
const SupportReport = React.lazy(() => import('./views/SupportReport'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

export const handleLogout = () => {
  localStorage.removeItem('persist:user');
  window.location.reload();
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // automatically requests fresh data for you in the background
      refetchOnWindowFocus: false,
      retry: 1,
      // will be return after 30 seconds
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// eslint-disable-next-line react/prefer-stateless-function
const App: React.FC = () => {
  const countryCode = useSelector((state: RootState) => selectCurrentCountry(state));
  const langaugeCode = useSelector((stateData: RootState) => selectCurrentLanguage(stateData));

  // const [reloadState, setReloadState] = useState(false);
  useEffect(() => {
    queryClient.clear();
  }, [countryCode]);

  // useEffect(() => {
  //   if (reloadState) {
  //     setReloadState(() => false);
  //     sessionStorage.setItem('reload', 'true');
  //     window.location.reload();
  //   }
  // }, [reloadState]);

  // useEffect(() => {
  //   const handleStorageEvent = (event: StorageEvent): void => {
  //     if (event.key === 'requestSyncSessionStorage') {
  //       localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
  //       localStorage.removeItem('sessionStorage');
  //     } else if (event.key === 'sessionStorage') {
  //       const sessionStorageItem = JSON.parse(event.newValue || '{}');
  //       // eslint-disable-next-line no-restricted-syntax, guard-for-in
  //       for (const key in sessionStorageItem) {
  //         sessionStorage.setItem(key, sessionStorageItem[key]);
  //       }
  //       sessionStorage.setItem('reload', 'false');
  //       setReloadState(() => true);
  //     }
  //   };

  //   const requestSyncSessionStorage = (): void => {
  //     if (!sessionStorage.length) {
  //       localStorage.setItem('requestSyncSessionStorage', moment().unix().toString());
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageEvent);
  //   requestSyncSessionStorage();

  //   return () => {
  //     window.addEventListener('storage', handleStorageEvent);
  //     requestSyncSessionStorage();
  //   };
  // }, []);

  const locale = useMemo(() => {
    if (langaugeCode === LanguageCode.ID) {
      return idLocale;
    }
    return enLocale;
  }, [langaugeCode]);

  return (
    <ConfigProvider locale={locale}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <React.Suspense fallback={loading}>
            <Toaster />
            <Switch>
              <PublicRoute exact path="/support-report/:data" component={SupportReport} />
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/2fa" component={TwoFA} />
              <PublicRoute exact path="/forget-password" component={ForgetPassword} />
              <ProtectedRoute exact path="/employer-company" component={SwitchEmployerCompany} />
              <Route exact path="/404" component={Page404} />
              <Route exact path="/500" component={Page500} />
              <ProtectedRoute path="/setup-password" component={SetupPassword} />
              <ProtectedRoute path="/" component={TheLayout} />
            </Switch>
          </React.Suspense>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
